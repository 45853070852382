import React from 'react'
import './experience.css'
import {SiApachemaven, SiKubernetes, SiPostgresql, SiSpring, SiSpringboot} from 'react-icons/si'
import {
    TbBrandAngular,
    TbBrandCss3,
    TbBrandNextjs,
    TbBrandJavascript,
    TbBrandVue,
    TbBrandPhp,
    TbBrandPython,
    TbBrandGit,
    TbBrandFirebase,
    TbBrandDocker,
    TbBrandReactNative,
    TbBrandSvelte,
    TbDeviceDesktopCheck
} from 'react-icons/tb'
import {useTranslation} from "react-i18next";
import {FaJenkins} from "react-icons/fa";
import {BsFiletypeJava} from "react-icons/bs";

const Experience = () => {
    const {t} = useTranslation();
    return (
        <section id="experience">
            <h5>{t("experience.sub_header")}</h5>
            <h2>{t("experience.header")}</h2>
            <div className="container experience_container">
                <div className="experience_frontend">
                    <h3>{t("experience.Frontend")}</h3>
                    <div className="experience_content">

                        <div className="experience_details">
                            <TbBrandAngular className="experience_details-icon"/>
                            <div>
                                <h4>Angular</h4>
                                <small className="text-light">{t("experience.Proficient")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <TbBrandReactNative className="experience_details-icon"/>
                            <div>
                                <h4>React</h4>
                                <small className="text-light">{t("experience.Proficient")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <TbBrandNextjs className="experience_details-icon"/>
                            <div>
                                <h4>Next.js</h4>
                                <small className="text-light">{t("experience.Proficient")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <TbBrandVue className="experience_details-icon"/>
                            <div>
                                <h4>Vue</h4>
                                <small className="text-light">{t("experience.Proficient")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <TbBrandJavascript className="experience_details-icon"/>
                            <div>
                                <h4>Javascript & Typescript</h4>
                                <small className="text-light">{t("experience.Proficient")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <TbBrandSvelte className="experience_details-icon"/>
                            <div>
                                <h4>Svelte</h4>
                                <small className="text-light">{t("experience.Intermediate")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <TbBrandCss3 className="experience_details-icon"/>
                            <div>
                                <h4>CSS</h4>
                                <small className="text-light">{t("experience.Proficient")}</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="experience_backend">
                    <h3>{t("experience.backend")}</h3>
                    <div className="experience_content">
                        <div className="experience_details">
                            <BsFiletypeJava className="experience_details-icon"/>
                            <div>
                                <h4>Java</h4>
                                <small className="text-light">{t("experience.Expert")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <BsFiletypeJava className="experience_details-icon"/>
                            <div>
                                <h4>Java EE</h4>
                                <small className="text-light">{t("experience.Expert")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <SiSpring className="experience_details-icon"/>
                            <div>
                                <h4>Spring</h4>
                                <small className="text-light">{t("experience.Expert")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <SiSpringboot className="experience_details-icon"/>
                            <div>
                                <h4>Spring Boot</h4>
                                <small className="text-light">{t("experience.Expert")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <TbBrandNextjs className="experience_details-icon"/>
                            <div>
                                <h4>Next.js</h4>
                                <small className="text-light">{t("experience.Proficient")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <TbBrandPhp className="experience_details-icon"/>
                            <div>
                                <h4>PHP</h4>
                                <small className="text-light">{t("experience.Expert")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <TbBrandPython className="experience_details-icon"/>
                            <div>
                                <h4>Python</h4>
                                <small className="text-light">{t("experience.Intermediate")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <TbDeviceDesktopCheck className="experience_details-icon"/>
                            <div>
                                <h4>Groovy</h4>
                                <small className="text-light">{t("experience.Intermediate")}</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="experience_devops">
                    <h3>{t("experience.devops")}</h3>
                    <div className="experience_content">
                        <div className="experience_details">
                            <TbBrandGit className="experience_details-icon"/>
                            <div>
                                <h4>Git</h4>
                                <small className="text-light">{t("experience.Expert")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <SiApachemaven className="experience_details-icon"/>
                            <div>
                                <h4>Maven</h4>
                                <small className="text-light">{t("experience.Intermediate")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <TbBrandDocker className="experience_details-icon"/>
                            <div>
                                <h4>Docker</h4>
                                <small className="text-light">{t("experience.Intermediate")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <SiKubernetes className="experience_details-icon"/>
                            <div>
                                <h4>Kubernetes</h4>
                                <small className="text-light">{t("experience.Intermediate")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <FaJenkins className="experience_details-icon"/>
                            <div>
                                <h4>Jenkins</h4>
                                <small className="text-light">{t("experience.Beginner")}</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="experience_database">
                    <h3>{t("experience.databases")}</h3>
                    <div className="experience_content">
                        <div className="experience_details">
                            <SiPostgresql className="experience_details-icon"/>
                            <div>
                                <h4>PostgreSQL</h4>
                                <small className="text-light">{t("experience.Expert")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <SiPostgresql className="experience_details-icon"/>
                            <div>
                                <h4>SQL</h4>
                                <small className="text-light">{t("experience.Expert")}</small>
                            </div>
                        </div>
                        <div className="experience_details">
                            <TbBrandFirebase className="experience_details-icon"/>
                            <div>
                                <h4>NoSQL</h4>
                                <small className="text-light">{t("experience.Intermediate")}</small>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Experience
