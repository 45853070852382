import React, { useEffect, useMemo, useRef, useState } from 'react'
import Header from '../components/header/Header'
import About from '../components/about/About'
import Contact from '../components/contact/Contact'
import Experience from '../components/experience/Experience'
import Navbar from '../components/navbar/Navbar'
import Portfolio from '../components/portfolio/Portfolio'
import Testimonials from '../components/testimonials/Testimonials'
import '../index.css'
import I18nMenue from "../components/i18nmenu/I18nMenue";
import { ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";

if (typeof window !== "undefined") {
    injectStyle();
}


function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observer = useMemo(
        () =>
            new IntersectionObserver((([entry]) => {
                setIsIntersecting(entry.isIntersecting);
                if (entry.isIntersecting) entry.target.classList.add('show');
            }), { root: null, threshold: [0, 0.2] }
            ),
        [],
    );

    useEffect(() => {
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);
    return isIntersecting;
}



const MainView = () => {

    const [inViewElements, setInViewElements] = useState([])
    // Refs
    const headerRef = useRef(null);
    const aboutRef = useRef(null);
    const experienceRef = useRef(null);
    const portfolioRef = useRef(null)
    const testimonialsRef = useRef(null)
    const contactRef = useRef(null);
    // InView
    const headerInView = useIsInViewport(headerRef);
    const aboutInView = useIsInViewport(aboutRef);
    const experienceInView = useIsInViewport(experienceRef);
    useIsInViewport(portfolioRef);
    useIsInViewport(testimonialsRef);
    const contactInView = useIsInViewport(contactRef);


    useEffect(() => {
        const tempArray = [];
        if (headerInView) tempArray.push("#")
        if (aboutInView) tempArray.push("#about")
        if (experienceInView) tempArray.push("#experience")
        if (experienceInView) tempArray.push("#porfolio")
        if (contactInView) tempArray.push("#contact")
        setInViewElements(tempArray);
    }, [headerInView, aboutInView, experienceInView, contactInView]);


    return (
        <>
            <ToastContainer
                position="bottom-center"
                style={{ "margin-bottom": "8.5rem" }}
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <I18nMenue />
            <Navbar headerInView={inViewElements} />
            <div ref={headerRef}><Header /></div>
            <div className='hidden' ref={aboutRef}><About /></div>
            <div className='hidden' ref={experienceRef}><Experience /></div>
            <div className='hidden' ref={portfolioRef}><Portfolio /></div>
            <div className='hidden' ref={testimonialsRef}><Testimonials /></div>
            <div className='hidden' ref={contactRef}><Contact /></div>
        </>
    )
}

export default MainView



