import React from 'react'
import "./portfolio.css"
import "../../index.css"
import {useTranslation} from "react-i18next";
import {getPortfolio} from "./portfolioService";


const Portfolio = () => {
    const {t, i18n} = useTranslation();
    const portfolioItems = getPortfolio();
    return (
        <section id="portfolio">
            <h5>{t("portfolio.sub_header")}</h5>
            <h2>{t("portfolio.header")}</h2>
            <div className="container portfolio_container">
                {
                    portfolioItems.map(({
                                            id, image, title, description, role,
                                            techstack, dateRange, githubLink, demoLink, portal
                                        }) => {
                        return (
                            <article key={id} className="portfolio_item">
                                <div className="">
                                    <img alt='' src={image}></img>
                                </div>
                                <div className="portfolio_item_header">
                                    <div className="details">
                                        <div className="details_header">
                                            <div className="w100">
                                                <h5>{i18n.language === 'de' && role.de}{i18n.language === 'en' && role.en}</h5>
                                                <h2>{i18n.language === 'de' && title.de}{i18n.language === 'en' && title.en}</h2>
                                                <small className='min-h-10'>{dateRange}&nbsp; </small>
                                            </div>
                                        </div>
                                        <hr/>
                                        {techstack &&
                                            <div className="technologies">
                                            <ul>
                                                <li>-</li>
                                                {techstack.map((e, index) => {
                                                    return <li key={index}>{e} &nbsp;-
                                                    </li>
                                                })}
                                            </ul>
                                        </div>
                                        }
                                        <hr/>
                                        <span>{i18n.language === 'de' && description.de}{i18n.language === 'en' && description.en}</span>
                                    </div>
                                </div>
                                <div className="portfolio_item-cta">
                                    {githubLink && <a href={githubLink} className="btn" target="_blank"
                                                      rel="noreferrer">Github</a>}
                                    {demoLink && <a href={demoLink} className="btn btn-primary" target="_blank"
                                                    rel="noreferrer">Demo</a>}
                                    {portal && <a href={portal} className="btn btn-primary" target="_blank"
                                                  rel="noreferrer">Portal</a>}
                                </div>
                            </article>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default Portfolio
