import React from 'react'
import "./footer.css"
import {useTranslation} from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer>
            <ul className="permalinks">
                <li>
                    <a href="/#">{t("footer.home")}</a>
                </li>
                <li>
                    <a href="/#about">{t("footer.about")}</a>
                </li>
                <li>
                    <a href="/#experience">{t("footer.experience")}</a>
                </li>
                {/*<li>*/}
                {/*    <a href="#services">{t("footer.services")}</a>*/}
                {/*</li>*/}
                <li>
                    <a href="/#portfolio">{t("footer.portfolio")}</a>
                </li>
                <li>
                    <a href="/#testimonials">{t("footer.testimonials")}</a>
                </li>
                <li>
                    <a href="/#contact">{t("footer.contact")}</a>
                </li>
                <li>
                    <a href={"/impressum"}>{t("footer.impressum")}</a>
                </li>
            </ul>

            {/*<div className="footer_socials">*/}
            {/*    <a href="">Facebook</a>*/}
            {/*    <a href="">Twitter</a>*/}
            {/*    <a href="">Whatsoever</a>*/}
            {/*</div>*/}
        </footer>
    )
}

export default Footer
