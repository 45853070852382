import React, {useEffect, useState} from 'react'
import {RiHomeLine} from 'react-icons/ri'
import {GoTerminal} from 'react-icons/go'
import {AiOutlineIdcard} from 'react-icons/ai'
import {BiMessageSquareDetail} from 'react-icons/bi'

import "./navbar.css"
import {useTranslation} from "react-i18next";

const Navbar = (props) => {
    const {t} = useTranslation();
    const [activeNav, setActiveNav] = useState('#');

    useEffect(() => {
        if (props.headerInView.length > 0) {
            setActiveNav(props.headerInView[0])
        }
    }, [props.headerInView, activeNav]);


    return (
        <nav>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" data-tooltip={t("navbar.home")} data-tooltip-location="bottom"
               className={activeNav === '#' ? 'active' : ''} onClick={() => setActiveNav('#')}><RiHomeLine/></a>
            <a href="#about" data-tooltip={t("navbar.about")} data-tooltip-location="bottom"
               className={activeNav === '#about' ? 'active' : ''}
               onClick={() => setActiveNav('#about')}><AiOutlineIdcard/></a>
            <a href="#experience" data-tooltip={t("navbar.experience")} data-tooltip-location="bottom"
               className={activeNav === '#experience' ? 'active' : ''}
               onClick={() => setActiveNav('#experience')}><GoTerminal/></a>
            {/*<a href="#services" data-tooltip={t("navbar.services")} data-tooltip-location="bottom" className={activeNav === '#services' ? 'active' : ''} onClick={() => setActiveNav('#services')}><RiCustomerService2Line /></a>*/}
            <a href="#contact" data-tooltip={t("navbar.contact")} data-tooltip-location="bottom"
               className={activeNav === '#contact' ? 'active' : ''}
               onClick={() => setActiveNav('#contact')}><BiMessageSquareDetail/></a>
        </nav>
    )
}

export default Navbar
