import React from 'react'
import CVDE from '../../assets/documents/CV_Matthias_Tritt_2023_Deutsch.pdf'
import CVEN from '../../assets/documents/CV_Matthias_Tritt_2023_Englisch.pdf'
import {useTranslation} from "react-i18next";

const CTA = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className="cta">
            <a href={i18n.language === "de" ? CVDE : CVEN} download className='btn header_btn'>{t('header.btn_download_cv')}</a>
            <a href="#contact" className='btn btn-primary header_btn'>{t('header.scroll')}</a>
        </div>
    )
}

export default CTA
