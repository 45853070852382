import React, {useRef} from 'react'
import {HiOutlineMail} from 'react-icons/hi'
import {AiOutlinePhone, AiOutlineWhatsApp} from 'react-icons/ai'
import './contact.css'
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import emailjs from '@emailjs/browser';

const Contact = () => {
    const {t} = useTranslation();
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_cmxyf6v', 'template_xbqk5ee', form.current, 'JsX9b3hIyRzWW7CIV')
            .then(() => {
                toast.dark(t("contact.SEND_MESSAGE_SUCESS"), {
                    type: "success",
                });
                e.target.reset();
            }, (error) => {
                toast.dark(t("contact.SEND_MESSAGE_FAIL"), {
                    type: "error",
                });
                console.log(error.text);
            });
    };

    return (

        <section id="contact">
            <h5>{t("contact.sub_header")}</h5>
            <h2>{t("contact.header")}</h2>

            <div className="container contact_container">

                <div className="contact_options">
                    <div className="contact_option">
                        <HiOutlineMail className="contact_option-icon"/>
                        <h4>{t("contact.MAIL")}</h4>
                        <h5>matthias.tritt.dev@gmail.com</h5>
                        <a href="mailto:matthias.tritt.dev@gmail.com" target="_blank" rel="noreferrer">{t("contact.WRITE_EMAIL")}</a>
                    </div>
                    <div className="contact_option">
                        <AiOutlinePhone className="contact_option-icon"/>
                        <h4>{t("contact.PHONE")}</h4>
                        <h5>+49 176 223 753 28</h5>
                        <a href="tel:+49 176 223 753 28" target="_blank" rel="noreferrer">{t("contact.CALL_ME")}</a>
                    </div>
                    <div className="contact_option">
                        <AiOutlineWhatsApp className="contact_option-icon"/>
                        <h4>Whatsapp</h4>
                        <h5>+49 176 223 753 28</h5>
                        <a href="https://wa.me/4917622375328"
                           target="_blank" rel="noreferrer">{t("contact.MESSAGE_ME")}</a>
                    </div>
                </div>

                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name='name' placeholder={t("contact.YOUR_NAME")} required/>
                    <input type="email" name="email" placeholder={t("contact.YOUR_MAIL")} required/>
                    <textarea name="message" rows="7" placeholder={t("contact.YOUR_MESSAGE")} required/>
                    {/*<button type="submit" className="btn btn-primary">{t("contact.SEND_MESSAGE_BTN")}</button>*/}
                    <button className="btn btn-primary">{t("contact.SEND_MESSAGE_BTN")}</button>
                </form>
            </div>
        </section>
    )
}


export default Contact
