// import ANNE_KAUFMANN from "../../assets/images/Anne_Kaufmann.jpeg";
import GENERIC_AVATAR from '../../assets/images/generic_avatar.png';

const testimonialsData = [
    {
        avatar: GENERIC_AVATAR,
        review_de: '"Die Zusammenarbeit mit Matthias ist immer konstruktiv und hat unser Portal innerhalb eines Jahres sehr viel weitergebracht. Er versteht sich nicht nur auf die saubere Softwareentwicklung, sondern ist zudem ein absolutes Talent im Projektmanagement. Also fachlich und menschlich ein super Typ."',
        review_en: 'The collaboration with Matthias is always constructive and has brought our portal a lot further within one year. He not only understands clean software development, but is also an absolute talent in project management. So professionally and personally a super guy.',
        name: 'Anne Kaufmann',
        title_de :'Geschäftsführerin Leginda GmbH',
        title_en :'Managing Director Leginda GmbH',
    }
]
export const getTestimonials = () => {
    return testimonialsData;
}

export default getTestimonials();