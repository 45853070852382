import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './services/i18n';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


/**
 * https://www.youtube.com/watch?v=G-Cr00UYokU
 * Timestamp: 2h20m
 *
 */

