import LEGINDA_WORDPRESS from "../../assets/images/leginda_wordpress.png";
import GENERAL_DEVELOPMENT from "../../assets/images/general_development.jpg";
import POKERMANGER from "../../assets/images/pokermanager.png";
import PORTFOLIO from "../../assets/images/portfoliopage.png";

const portfolioItems = [
    {
        id: 1,
        title: {
            de: 'IT-Beratung',
            en: 'IT-Beratung'
        },
        description: {
            en: "IT-Beratung, Projekt-Durchführung und Rollout spezieller Lösungen für kleine und mittelständische Unternehmen",
            de: 'IT consulting, project implementation and rollout of special solutions for small and medium-sized companies'
        },
        role: {
            de: 'IT-Berater',
            en: 'IT consultant'
        },
        techstack: ['Linux & Windows-Server', 'Networking', 'Ubuntu', 'Hardware rollout'],
        dateRange: '2016 - 2019',
    },
    {
        id: 2,
        // image: LEGINDA_WORDPRESS,
        title: {
            de: 'IT-Beratung bei Ford of Europe und Ford Credit Europe Bank',
            en: 'IT consulting at Ford of Europe and Ford Credit Europe Bank'
        },
        description: {
            en: "IT-Beratung und Projektumsetzung bei Ford of Europe und Ford Credit Europe Bank",
            de: 'IT-Beratung und Projektumsetzung bei Ford of Europe und Ford Credit Europe Bank'
        },
        role: {
            de: 'IT-Berater',
            en: 'IT consultant'
        },
        techstack: ['BPMN', 'Windows & Linux-Server', 'Custom Data management and Migration Tooling'],
        dateRange: '2016 - 2019',
    },
    {
        id: 3,
        image: PORTFOLIO,
        title: {
            de: 'Portfolio-Website',
            en: 'Portfolio Site'
        },
        description: {
            de: 'Meine Portfolio-Website, die Sie gerade betrachten.',
            en: 'My portfolio website that you are currently viewing.'
        },
        role: {
            de: 'Entwicklung und Design',
            en: 'Developer and Designer'
        },
        techstack: ['React', 'Firebase'],
        dateRange: '',
        portal: 'www.askforcode.de'
    },
    {
        id: 4,
        image: POKERMANGER,
        title: {
            de: 'Homegame Pokerturnier Manager',
            en: 'Homegame Poker Tournament Manager'
        },
        description: {
            de: 'Mit dieser Webanwendung kannst du unkompliziert kleine Pokerturniere planen und durchführen. Zu den Funktionen gehören unter anderem die automatische Berechnung der Blindstufen (einschließlich eines Timers dafür) sowie der Auszahlungsstruktur. Darüber hinaus ermöglicht dir die Anwendung ein einfaches Verwalten der Spieler und das Festlegen der Spiellänge. So kannst du dich voll und ganz auf das strategische Spiel konzentrieren und das Turnier in vollen Zügen genießen.',
            en: 'With this web application, you can easily plan and organize small poker tournaments. Features include automatic calculation of blind levels (including a timer) and payout structure. Additionally, the application allows you to effortlessly manage players and set the game length. This way, you can fully concentrate on the strategic game and enjoy the tournament to the fullest.'
        },        
        role: {
            de: 'Frontend-Developer',
            en: 'Frontend-Developer'
        },
        techstack: ['Angular', 'Firebase'],
        dateRange: '',
        portal: 'https://pokerturniermanager.web.app'
    },
    {
        id: 5,
        image: GENERAL_DEVELOPMENT,
        title: {
            de: 'Weitere Projekte',
            en: 'Additional Projects'
        },
        description: {
            de: 'Im Laufe meiner Karriere hatte ich die Gelegenheit, an einer Vielzahl von Softwareentwicklungsprojekten zu arbeiten. Während mein Portfolio eine Auswahl meiner neuesten Arbeiten zeigt, gibt es weitere Projekte, zu denen ich beigetragen habe und die nicht öffentlich zugänglich sind. Diese Projekte wurden für den internen Gebrauch innerhalb eines Unternehmens oder einer Organisation entwickelt und sind nur über ein Intranet zugänglich. Trotz ihrer begrenzten Sichtbarkeit haben diese Projekte wertvolle Erfahrungen gebracht und meine Fähigkeiten als Softwareentwickler verbessert.',
            en: "Throughout my career, I've had the opportunity to work on a variety of software development projects. While my portfolio showcases a selection of my recent work, there are additional projects I've contributed to that are not publicly accessible. These projects were developed for internal use within a company or organization and are only accessible via an intranet. Despite their limited visibility, these projects have provided valuable experiences and enhanced my skills as a software developer."
        },
        role: {
            de: 'Softwareentwickler',
            en: 'Developer'
        },
        techstack: ['Angular', 'React', 'Java EE', 'PHP', 'Next.js'],
        dateRange: '15.02.2019 - now ',
    },
    {
        id: 6,
        title: {
            de: 'Echtzeit-Erfassung von Zug- und Containerpositionen in einem Stahlwerk',
            en: 'Real-time Recording of Train and Container Positions in a Steel Mill'
        },
        description: {
            en: "Web app for tracking and analyzing wagons in a steel mill using sensor technology",
            de: 'Web-App zur Echtzeit-Erfassung und Analyse von Zug- und Containerpositionen in einem Stahlwerk mit Hilfe von Sensortechnik'
        },        
        role: {
            de: 'Frontend-Developer',
            en: 'Frontend-Developer'
        },
        techstack: ['Java EE', 'Spring', 'Hibernate', 'Angular', 'REST', 'Python', 'PostgreSQL', 'Maven'],
    },
    {
        id: 8,
        title: {
            de: 'Datenfassung und -verwaltung für einen Automobilzuliefer-Konzern',
            en: 'Data Collection and Management for an Automotive Supplier Group'
        },
        description: {
            de: "Interne Web-App zur Verwaltung und Erfassung von Millionen von Datensätzen aus weltweit verteilten Standorten",
            en: 'Internal web application for managing and capturing millions of data records from globally distributed locations'
        },
        
        role: {
            de: 'Full Stack Developer',
            en: 'Full Stack Developer'
        },
        techstack: ['Java EE', 'Angular', 'REST', 'PostgreSQL', 'Docker', 'Maven'],
    },
    {
        id: 9,
        title: {
            de: 'Firmenwebpräsenz',
            en: 'Corporate Website'
        },
        description: {
            de: "Die Online-Präsenz meines ehemaligen Arbeitgebers.",
            en: 'The online presence of my former employer.'
        },
        
        role: {
            de: 'Frontend-Developer',
            en: 'Frontend-Developer'
        },
        techstack: ['Vue.js', 'SQLite', 'Strapi'],

        portal: 'https://www.meta-level.de'
    },
    {
        id: 10,
        title: {
            de: 'Zeiterfassung',
            en: 'Time Tracking'
        },
        description: {
            de: "Ein internes Zeiterfassungssystem für meinen früheren Arbeitgeber Meta-Level Software AG",
            en: 'An internal time tracking system for my former employer Meta-Level Software AG'
        },
        
        role: {
            de: 'Full Stack Developer',
            en: 'Full Stack Developer'
        },
        techstack: ['Java EE', 'Angular', 'REST', 'PostgreSQL', 'Maven'],
    },
    {
        id: 11,
        image: LEGINDA_WORDPRESS,
        title: {
            de: 'LEGINDA – Business Translation',
            en: 'LEGINDA – Business Translation'
        },
        description: {
            de: "Die Software umfasst die Möglichkeit, Aufträge zu erstellen und zu erteilen, die Vergabe dieser Aufträge an Subunternehmen, die Abwicklung der Aufträge, die Administration, die Buchhaltung und die Zahlungsabwicklung. Dieses Portal ermöglicht es der Firma, ihre Geschäftsprozesse effizienter und schneller abzuwickeln und ihren Kunden einen besseren Service zu bieten.",
            en: 'The software includes features for customers to create and place orders, awarding these orders to subcontractors, order processing, administration, accounting, and payment processing. This portal enables the company to streamline its business processes more efficiently and quickly, providing better service to its customers.'
        },
        
        role: {
            de: 'Leaddeveloper & Projektmanager',
            en: 'Leaddeveloper & Project manager'
        },
        techstack: ['Angular', 'PHP', 'C#', 'Java', 'PostgreSQL'],
        dateRange: '01.01.2021 - 31.12.2022',
        portal: 'https://www.leginda.de'
    },
    {
        id: 12,
        title: {
            de: 'Digitalisierung von Kraftfahrzeug-Zulassungsbehörden in Deutschland',
            en: 'Digitization of Vehicle Registration Authorities in Germany'
        },
        description: {
            de: "Weiterentwicklung und Wartung der Software für die Zulassungsbehörden in Deutschland. Diese wird flächendeckend in Deutschland eingesetzt und von der AKDB entwickelt. Kommunen können damit Nutzern ermöglichen, Kennzeichen zu reservieren, Fahrzeuge zuzulassen, abzumelden und umzumelden. All dies ist ohne einen Besuch vor Ort möglich.",
            en: 'Further development and maintenance of the software for vehicle registration authorities in Germany. It is used nationwide and developed by the AKDB. Municipalities can enable users to reserve license plates, register, deregister, and report vehicles. All of this is possible without a visit to the office.'
        },        
        role: {
            de: 'Frontend-Developer',
            en: 'Frontend-Developer'
        },
        techstack: ['Vue', 'Java', 'Camunda', 'Groovy', 'PostgreSQL', 'Maven', 'Docker', 'Kubernetes'],
        dateRange: '01.03.2023 - now',
    }

]

export const getPortfolio = () => {
    return portfolioItems.sort((a, b) => b.id - a.id);
}