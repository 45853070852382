import React from 'react'
import CTA from './CTA'
import ME from '../../assets/images/myfoto.png'
import HeaderSocials from './HeaderSocials'
import "./header.css"
import "../../index.css"
import {useTranslation} from "react-i18next";


const Header = () => {
    const {t} = useTranslation();
    return (
        <header>
            <div className="container header_sub_container">
                <div className="header_container">
                    <div className="container header_sub_container">
                        <h5>{t('header.sub_header')}</h5>
                        <h1 className="name_animation"><span>Matthias Tritt</span></h1>
                        <div>
                            {/*<div className="type_writer_container">*/}
                                <div className="">{t('header.slogan')}</div>
                            {/*</div>*/}
                        </div>

                    </div>
                    <div>
                        <CTA/>
                    </div>
                </div>
                <HeaderSocials/>
                <div className='me'>
                    <img src={ME} alt=''/>
                </div>

            </div>
        </header>
    )
}

export default Header
