import React from 'react'

import "./i18nmenu.css"
import {useTranslation} from "react-i18next";
import ReactCountryFlag from "react-country-flag"

const I18nMenue = () => {
    const {t, i18n} = useTranslation();
    const setActiveLanguage = (langCode) => {
        i18n.changeLanguage(langCode);
    }

    return (
        <div className={'menu_bar'}>
            <div data-tooltip={t("i18nmenu.de")} data-tooltip-location="bottom" className={i18n.language === 'de' ? 'active' : ''} onClick={() => setActiveLanguage('de')}> <ReactCountryFlag countryCode="DE" /></div>
            <div data-tooltip={t("i18nmenu.en")} data-tooltip-location="bottom" className={i18n.language === 'en' ? 'active' : ''} onClick={() => setActiveLanguage('en')}> <ReactCountryFlag countryCode="US" /></div>
        </div>
    )
}


export default I18nMenue
