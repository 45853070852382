import React from 'react'
import ME2 from '../../assets/images/myfoto2.jpg'

import {MdOutlineBadge} from 'react-icons/md'
import {RiTeamLine} from 'react-icons/ri'
import {FaUniversity} from 'react-icons/fa'
import "./about.css"
import {useTranslation} from "react-i18next";

const About = () => {
    const {t} = useTranslation();
    return (
        <section id='about'>
            <h5>{t("about_me.sub_header")}</h5>
            <h2>{t("about_me.header")}</h2>
            <div className="container about_container">
                <div className="about_me">
                    <div className="about_me_image">
                        <img src={ME2} alt=''/>
                    </div>
                </div>
                <div className="about_content">
                    <div className="about_cards">
                        <article className="about_card">
                            <MdOutlineBadge className="about_icon"/>
                            <h5>{t("about_me.badge1.header")}</h5>
                            <small>{t("about_me.badge1.sub_header")}</small>
                        </article>
                        <article className="about_card">
                            <FaUniversity className='about_icon'/>
                            <h5>{t("about_me.badge2.header")}</h5>
                            <small>{t("about_me.badge2.sub_header")}</small>
                        </article>
                        <article className="about_card">
                            <RiTeamLine className='about_icon'/>
                            <h5>{t("about_me.badge3.header")}</h5>
                            <small>{t("about_me.badge3.sub_header")}</small>
                        </article>
                        
                    </div>

                </div>


            </div>
            <div className='container about_me_info_text'>
                <p className='about_content'  dangerouslySetInnerHTML={{__html: t("about_me.info_text")}}></p>
                <a href="#contact" className="btn btn-primary"> {t("about_me.btn_contact")}</a>
            </div>
        </section>
    )
}
export default About
