import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import './index.css'
import {injectStyle} from "react-toastify/dist/inject-style";
import MainView from "./pages/MainView";
import Footer from "./components/footer/Footer";
import Impressum from "./components/Impressum/Impressum";

if (typeof window !== "undefined") {
    injectStyle();
}


const App = () => {

    return (
        <>
            <div className="content">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<MainView/>}/>
                        <Route path="impressum" element={<Impressum/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
            <div>
                <Footer/>
            </div>


        </>
    )
}

export default App




