import React from 'react'
import "./testimonials.css"


import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import {useTranslation} from "react-i18next";
import {getTestimonials} from "./testimonialsService";



const Testimonials = () => {
    const testimonialsData = getTestimonials();
    const {t, i18n} = useTranslation();
    return (
        <section id="testimonials">
            <h5>{t("testimonials.sub_header")}</h5>
            <h2>{t("testimonials.header")}</h2>
            <Swiper
                pagination={{clickable: true}}
                modules={[Pagination]}
                spaceBetween={40}
                slidesPerView={1}
                className="container testimonials_container"
            >
                {testimonialsData.map(({avatar, review_de, review_en, name, title_de, title_en}, index) => {
                        return (
                            <SwiperSlide key={index} className="testimonial">
                                <div className="client_avatar">
                                    <img src={avatar} alt=''/>
                                </div>
                                <h4 className="client_review">
                                    {(i18n.language === 'de' && review_de) && review_de }
                                    {(i18n.language === 'en' && review_en) && review_en }
                                </h4>
                                <div className="client_name">
                                    <small >{name}
                                        {(i18n.language === 'de' && title_de) &&  " - " + title_de}
                                        {(i18n.language === 'en' && title_en) && " - " + title_en }
                                    </small>

                                </div>
                            </SwiperSlide>
                        )
                    }
                )}
            </Swiper>
        </section>
    )
}

export default Testimonials
