import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {ImGithub} from 'react-icons/im'
import "./header.css"


const HeaderSocials = () => {
    return (
        <div className='header_socials'>
            <a href='https://de.linkedin.com/in/matthias-tritt-3b576721b' target='_blank' rel="noreferrer"><BsLinkedin/></a>
            <a href='https://github.com/yougotasked/' target='_blank' rel="noreferrer"><ImGithub/></a>
        </div>
    )
}

export default HeaderSocials
